/**
 * Плагин для редиректа страниц при запуске вне мобильного устройства
 */
export default defineNuxtPlugin(() => {
  const router = useRouter();
  const { $pwa } = useNuxtApp();
  router.beforeEach((to, _from, next) => {
    if (!to.meta.noPWA && (!isMobile() || !$pwa?.isPWAInstalled)) {
      next({ name: 'to-install' });
      return;
    }
    next();
  });
});
