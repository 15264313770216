<script setup lang="ts">
import { isMobile } from '@/utils/device.utils';

const { $pwa } = useNuxtApp();

const page = ref<HTMLElement | null>(null);

onMounted(() => {
  if ($pwa?.offlineReady) logDevMessage('App ready to work offline');
  if (page.value && $pwa && !$pwa.isPWAInstalled && isMobile()) {
    page.value.classList.add('-no-pwa');
  }
});

useHead({
  titleTemplate: (titleChunk) => (titleChunk ? `${titleChunk} - ${APP_NAME}` : `${APP_NAME}`),
});
</script>
<template>
  <div ref="page" class="app">
    <MToast />
    <NuxtLoadingIndicator />
    <NuxtPwaAssets />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
