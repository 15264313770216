<script setup lang="ts">
import { useToast } from 'primevue/usetoast';
import { useNetwork } from '@vueuse/core';

const toast = useToast();

const { isOnline } = useNetwork();

watch(isOnline, (online) => {
  toast.removeAllGroups();

  if (!online) {
    toast.add({
      severity: 'error',
      summary: 'Потеряно соединение с интернетом',
    });
  } else {
    toast.add({
      severity: 'success',
      summary: 'Cоединение восстановлено',
      life: 5000,
    });
  }
});
</script>
<template>
  <Toast position="top-center" />
</template>

<style lang="scss">
.p-toast {
  display: inline-block !important;
  width: auto !important;
  min-width: 260px;
  padding-top: calc(6px + env(safe-area-inset-top));
  .p-toast-message {
    border: none !important;
    color: $text-primary !important;
    background-color: #fff !important;
    border-radius: 10px !important;
  }
  .p-toast-message-text {
    display: block;
  }
  .p-toast-close-button {
    display: none;
  }
  .p-toast-detail,
  .p-toast-summary {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.15px;
  }
  .p-toast-message-content {
    padding: 8px 10px;
    gap: 6px;
  }
  .p-toast-message-icon {
    margin-top: 2px;
  }
  .p-toast-message-icon.error-icon {
    width: 20px;
    height: 20px;
    background-image: url(@/assets/svg/common/toast-error.svg);
  }
  .p-toast-message-error .p-icon {
    color: $text-negative;
  }
  .p-toast-message-success .p-icon {
    color: $text-positive;
  }
}
</style>
